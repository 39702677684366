/* Centering the popup */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align popup at the top */
    z-index: 9999; /* Ensure it's above other elements */
    overflow-y: auto; /* Allow scrolling if content is too long */
    padding-top: 80px; /* Add padding to push it below the header */
}

.linkedin-post {
    width: 90%; /* Make it responsive for smaller screens */
    max-width: 600px; /* Limit max width for larger screens */
    border-radius: 8px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    position: relative;
}

/* Custom Close Icon */
.custom-close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    color: #333; /* Darker color for better visibility */
}

.custom-close-icon:hover {
    color: red; /* Hover effect for close icon */
}

/* Adjustments to other styles */
.linkedin-post {
    position: relative; /* Ensure relative positioning for absolute elements like the close icon */
}

/* Post Header */
.post-header {
    display: flex;
    align-items: center;
}

.profile-pic {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 12px; /* Ensure proper spacing between image and text */
}

.user-info h3 {
    margin-top: 0;
    margin-bottom: 4px; /* Reduce space between name and job title */
    font-size: 16px; /* Similar to LinkedIn's name font size */
}

.user-info p {
    margin-top: 2px; /* Less margin between lines of text */
    font-size: 14px; /* Slightly smaller for job title */
}

/* Post Content */
.post-content {
    font-size: 14px; /* Match LinkedIn's content size */
    line-height: 1.6em; /* Improved readability */
}

.post-content a {
    color: #0077b5; /* LinkedIn blue for links */
}

.post-content a:hover {
    text-decoration: underline;
}

/* Post Image */
.post-image img {
    max-width: 100%;
}

/* Post Footer */
.post-footer {
   margin-top: 16px;
   font-size: 13px; 
   color: #666666; 
}

.post-footer a {
   color: #0077b5; 
}

/* Media Queries for Mobile Responsiveness */

@media (max-width: 768px) {
   .linkedin-post {
       width: 95%; 
       padding-left: 12px; 
       padding-right: 12px; 
   }

   .post-header {
       flex-direction: column; 
       align-items: flex-start;
   }

   .profile-pic {
       margin-right: 0; 
       margin-bottom: 8px; 
   }

   .user-info h3 {
       font-size: 14px; 
   }

   .user-info p {
       font-size: 13px; 
   }

   .post-content {
       font-size: 13px; 
   }
}