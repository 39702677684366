.flashcard {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #fff;
    border-radius: 0;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    padding: 20px;
    z-index: 1000;
    transition: transform 0.4s ease, opacity 0.4s ease;
    opacity: 0;
    transform: translateY(100%); /* Start off-screen at the bottom */
    font-family: 'Google Sans', sans-serif;
    color: #333;
    width: 90%;
    max-width: 400px;
    box-sizing: border-box;
  }
  
  .flashcard.open {
    opacity: 1;
    transform: translateY(0); /* Slide into view */
  }
  
  .flashcard.closed {
    opacity: 0;
    transform: translateY(100%); /* Slide out of view */
  }
  
  .flashcard h2 {
    margin: 0 0 10px;
    font-size: 22px;
    background: linear-gradient(90deg, #4285F4, #EA4335);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  
  .flashcard p {
    margin: 0 0 15px;
    font-size: 16px;
    color: #5F6368;
  }
  
  .flashcard a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    padding: 12px 24px;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    transition: background-color 0.3s ease;
    box-shadow: none;
    cursor: pointer;
    width: 100%;
    max-width: 200px;
  }
  
  .flashcard a:hover {
    background-color: #4e4e4e;
    color: #ffffff !important;
  }
  
  .flashcard a:active {
    background-color: #222;
    color: #ffffff !important;
  }
  
  @media (max-width: 600px) {
    .flashcard {
      bottom: 10px;
      right: 10px;
      padding: 15px;
    }
  
    .flashcard h2 {
      font-size: 20px;
    }
  
    .flashcard p {
      font-size: 14px;
    }
  
    .flashcard a {
      padding: 10px 20px;
      font-size: 14px;
    }
  }